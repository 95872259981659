* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-open);
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: white;
}
