.footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;

  a {
    font-size: 13px;

    &:hover {
      text-decoration: underline;
    }
  }
}
