.header {
  background-color: black;
  padding: 16px 80px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    justify-content: center;
    padding: 16px 40px;
  }

  &__logos {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    img {
      height: 50px;
    }
  }

  &__img {
    img {
      height: 70px;
    }
  }

  nav {
    @media screen and (max-width: 1200px) {
      display: none;
    }

    ul {
      display: flex;
      justify-content: space-between;
      gap: 35px;
      font-size: 14px;

      li {
        padding: 5px;

        &:hover {
          border-bottom: 3px solid rgb(247, 165, 1);
          padding-bottom: 2px;
        }
      }
    }
  }

  &__formulario {
    font-size: 14px;
    padding: 10px;
    border: 2px solid rgb(247, 165, 1);
    border-radius: 15px;

    &:hover {
      background-color: rgb(247, 165, 1);
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
