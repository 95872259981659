.container {
  background-color: whitesmoke;
  padding: 100px 120px 120px;
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 32px 16px;
  }

  label {
    font-size: 14px;
  }

  &__texts {
    width: 55%;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin: 0;
    }

    h3 {
      color: rgb(247, 165, 1);
      font-size: 30px;

      @media screen and (max-width: 1024px) {
        font-size: 26px;
      }
    }

    figure {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      @media screen and (max-width: 1024px) {
        margin-top: 10px;
      }

      img {
        width: 200px;
      }
    }
  }

  &__forms {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 40px;
    }

    &__nome,
    &__data,
    &__celular,
    &__email {
      height: 32px;
      margin-bottom: 5px;
      border-radius: 8px;
      border: 1px solid var(--gray-400);
      padding: 2px 6px;
      font-size: 16px;
      color: var(--gray);

      &:focus {
        outline-color: rgb(247, 165, 1);
      }

      &::placeholder {
        color: var(--gray-400);
      }
    }

    &__tiragens {
      display: flex;
      flex-direction: column;
      gap: 7px;
      margin-top: 10px;
      margin-bottom: 10px;

      input {
        margin-right: 3px;
      }
    }

    &__mensagem {
      height: 150px;
      border-radius: 8px;
      border: 1px solid var(--gray-400);
      padding: 3px 6px;
      font-size: 16px;
      margin-bottom: 10px;
      margin-top: 4px;
      color: var(--gray);

      &:focus {
        outline-color: rgb(247, 165, 1);
      }
    }

    &__botao {
      border-radius: 8px;
      color: white;
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
      background-color: rgb(247, 165, 1);
      border: none;
      margin-bottom: 3px;

      &:hover {
        background-color: orange;
      }
    }

    span {
      text-align: center;
    }
  }
}

/* Estilo para o modal */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*overflow: auto;*/
  background-color: rgba(0, 0, 0, 0.4);

  /* Estilo para o conteúdo do modal */
  .modal-content {
    background-color: whitesmoke;
    margin: 220px auto; /* Centraliza o conteúdo na tela */
    padding: 20px;
    border: 3px solid orange; /* Borda laranja */
    width: 40%;
    height: 200px;
    border-radius: 15px; /* Borda arredondada */

    @media screen and (max-width: 1024px) {
      margin: 150px auto; /* Centraliza o conteúdo na tela */
      width: 80%; /* 80% da largura da tela */
    }

    strong {
      font-size: 24px;
      color: green;
    }
  }

  /* Estilo para o botão de fechar */
  .close {
    color: orange; /* Cor laranja */
    float: right;
    font-size: 30px;
    font-weight: bold;
    margin-top: -10px;
  }

  .close:hover,
  .close:focus {
    color: #555;
    text-decoration: none;
    cursor: pointer;
  }

  figure {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    img {
      width: 50%;

      @media screen and (max-width: 1024px) {
        width: 65%;
      }
    }
  }
}
