/*BANNER*/
.banner {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 80px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-image: url("../imgs/sala.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;

  @media screen and (max-width: 480px) {
    background-image: url("../imgs/lufundo.jpg");
    justify-content: flex-end;
    align-items: flex-start;
    background-position: bottom;
    padding: 16px;
    gap: 10px;
    height: 550px;
  }

  h1 {
    font-family: "Roboto", sans-serif;
    font-size: 50px;
    margin-bottom: -20px;
    /*background-color: white;*/
    /*color: black;*/
    @media screen and (max-width: 1024px) {
      text-align: center;
      font-size: 32px;
    }

    @media screen and (max-width: 500px) {
      padding: 0;
      background-color: unset;
      font-size: 32px;
      margin-bottom: 0px;
      text-align: left;
      width: 90%;
      color: white;
    }
  }

  p {
    font-size: 18px;
    font-weight: 700;
    background-color: white;
    color: black;
    padding: 2px 12px;
    /*background: rgba(8, 8, 8, 0.5);*/

    border-radius: 10px;

    @media screen and (max-width: 1024px) {
      font-size: 14px;
      display: none;
    }
  }

  span {
    /*NONE*/
    display: none;

    background: rgba(8, 8, 8, 0.5);
    color: rgb(199, 194, 194);
    border-radius: 6px;
    padding: 6px;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      font-size: 10px;
    }
  }

  &__formulario {
    display: none;
    font-size: 18px;
    padding: 10px;
    border: 2px solid white;
    border-radius: 15px;

    @media screen and (max-width: 1024px) {
      display: inherit;
      font-size: 14px;
      background: rgba(8, 8, 8, 0.5);
    }

    &:hover {
      background-color: white;
      color: black;
    }
  }

  &__redes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    background: rgba(8, 8, 8, 0.5);
    color: rgb(199, 194, 194);
    padding: 10px;
    border-radius: 15px;

    @media screen and (max-width: 1024px) {
      align-items: flex-start;
    }

    small {
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }

    ul {
      display: flex;
      gap: 15px;

      a {
        font-size: 14px;

        img {
          width: 32px;
        }
      }
    }
  }
}

/*DESCRICAO*/
.descricao {
  justify-content: center;
  background-color: black;
  padding: 75px 16px 60px;
  color: white;

  @media screen and (max-width: 1024px) {
    padding-top: 30px;
  }

  h2 {
    font-weight: 400;
    font-size: 18px;
    text-align: center;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }

  &__inferior {
    margin-bottom: 20px;
  }
}

/*SOBRE MIM*/
.conteudo {
  padding: 30px 120px 25px;
  color: black;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  gap: 70px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
  }

  &__h3 {
    width: max-content;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 35px;

    @media screen and (max-width: 1024px) {
      font-size: 25px;
      margin-top: 0;
    }

    h3 {
      color: white;
      background-color: rgb(247, 165, 1);
      border-radius: 8px;
      padding: 0 10px;
    }
  }

  p {
    margin-bottom: 10px;
    margin-left: 3px;
  }

  figure {
    background-image: url("../imgs/fundo.png");
    padding: 4px 6px;
    border-radius: 52px;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    img {
      height: 500px;
      border-radius: 50px;
      /*border: 6px solid rgb(247, 165, 1);*/

      @media screen and (max-width: 1024px) {
        height: 400px;
      }
    }
  }
}

/*FORMACAO*/
.conteudoformacao {
  padding: 40px 120px 40px;
  color: white;
  background-color: black;
  display: flex;
  align-items: center;
  gap: 70px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 20px;
    gap: 40px;
  }

  &__h3 {
    background-image: url("../imgs/fundo.png");
    width: max-content;
    padding: 4px;
    margin-bottom: 10px;

    h3 {
      background-color: black;
      padding: 5px 10px;
    }
  }

  p {
    margin-bottom: 10px;
    margin-left: 3px;
  }
}

/*TAROT*/
.conteudotarot {
  padding: 40px 160px;
  color: white;
  background-image: url("../imgs/tarotfundo.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  gap: 70px;

  @media screen and (max-width: 1024px) {
    padding: 40px 20px;
    flex-direction: column;
  }

  &__h3 {
    background-image: url("../imgs/fundo.png");
    width: max-content;
    padding: 4px;
    margin-bottom: 20px;

    h3 {
      background-image: url("../imgs/tarotfundo.jpg");
      padding: 5px 10px;
    }
  }

  p {
    margin-bottom: 10px;
    margin-left: 3px;
  }

  figure {
    /*padding: 6px;
    background-image: url("../imgs/fundo.png");
    border-radius: 40px;*/
    @media screen and (max-width: 1024px) {
      display: none;
    }

    img {
      height: 400px;
      border-radius: 35px;
      border: 3px solid rgb(170, 122, 25);
    }
  }
}

/*ATENDIMENTOS*/
.conteudo2 {
  padding: 40px 120px 80px;
  color: black;
  background-color: whitesmoke;
  /*background-color: rgb(247, 165, 2);*/
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  gap: 70px;

  @media screen and (max-width: 1024px) {
    padding: 40px 16px;
    flex-direction: column;
  }

  &__h3 {
    display: flex;
    justify-content: center;
    background-color: rgb(247, 165, 1);
    border-radius: 8px;
    margin-bottom: 30px;
    /*background-image: url("../imgs/fundo.png");
    width: max-content;
    padding: 6px;
    margin: 15px auto 20px;*/

    h3 {
      color: white;
      /*background-color: rgb(247, 165, 1);*/
      padding: 5px 10px;
      font-size: 40px;

      @media screen and (max-width: 700px) {
        font-size: 30px;
      }
    }
  }

  h4 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #292929;

    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }
  }

  &__paragrafo {
    margin-bottom: 10px;
  }

  &__agendar {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;

    a {
      background-color: rgb(247, 165, 1);
      padding: 6px 10px;
      border-radius: 10px;
      font-size: 18px;
    }
  }

  &__fundo {
    /*background-image: url("../imgs/fundo.png");*/
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.6);
    padding: 8px;
    margin-bottom: 30px;
    border-radius: 18px;
  }

  &__tiragem {
    background-color: whitesmoke;
    /*background-color: rgb(247, 165, 1);*/
    padding: 10px;
    border-radius: 16px;
    /*box-shadow: 1px 1px 5px 1px black;*/

    strong {
      color: #292929;
    }

    &__exemplos {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;

      div {
        width: 50%;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }

    img {
      width: 32px;
      margin-right: 5px;
    }
  }

  &__opcoes {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }

    &__online,
    &__presencial {
      border-radius: 8px;
      /*box-shadow: 5px 2px 10px 4px black;*/
      /*background-color: rgb(247, 165, 1);*/
      background-color: whitesmoke;
      width: 400px;
      min-height: 300px;
      padding: 16px;
      text-align: center;

      a {
        color: orange;
      }

      @media screen and (max-width: 1024px) {
        width: unset;
        padding: 16px;
      }

      h5 {
        font-size: 24px;
        margin: 10px auto;
        width: max-content;
        color: rgb(247, 165, 1);
        padding: 5px 10px;
        border-radius: 8px;

        @media screen and (max-width: 1024px) {
          font-size: 20px;
          margin: 6px auto;
        }
      }
    }
  }
}

/*AGENDAR / VENDAS*/
.conteudovendas {
  padding: 40px 160px 60px;
  color: white;
  background-image: url("../imgs/tarotfundo.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    padding: 40px 20px;
  }

  h3 {
    font-size: 50px;
    margin-bottom: 10px;

    @media screen and (max-width: 1024px) {
      font-size: 35px;
    }
  }

  &__whats {
    background-color: #1db855;
    padding: 10px 20px 8px;
    border-radius: 20px;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 50px;
    display: flex;

    img {
      background-color: white;
      border-radius: 50%;
      padding: 2px;
      width: 30px;
      height: 30px;
      margin-left: 10px;

      @media screen and (max-width: 1024px) {
        width: 50px;
        height: 50px;
      }
    }
  }

  &__subtitulo {
    margin-bottom: 20px;
    width: 480px;
    font-size: 18px;
    text-align: center;

    @media screen and (max-width: 1024px) {
      width: unset;
    }
  }

  &__valores {
    display: flex;
    gap: 50px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__valor {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;
    align-items: flex-end;

    &__texto {
      font-size: 30px;
      background-color: rgb(247, 165, 1);
      border-radius: 4px;
      padding: 8px;
      height: max-content;
    }

    &__preco {
      background-color: rgb(235, 230, 230);
      color: rgb(36, 33, 33);
      font-size: 40px;
      border-radius: 8px;
      padding: 2px 8px;
    }
  }
}

/*CONTATO*/
.conteudo3 {
  padding: 40px 120px;
  color: white;
  background-color: black;
  display: flex;
  gap: 150px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 40px 20px;
    flex-direction: column;
    gap: 40px;
  }

  &__h3 {
    background-image: url("../imgs/fundo.png");
    width: max-content;
    padding: 4px;
    margin-bottom: 16px;

    h3 {
      background-color: black;
      padding: 5px 10px;

      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }

  h4 {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 4px;

    a {
      &:hover {
        color: rgb(184, 181, 181);
        background-color: unset;
      }
    }

    li {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__mapa {
    margin-top: 12px;
  }
}

.separador {
  width: 100%;
  height: 7px;
  background-image: url("../imgs/fundo.png");
  /*filter: brightness(1.5);*/
}

.separador-pequeno {
  margin: 0 auto 0;
  width: 220px;
  height: 4px;
  border-radius: 10px;
  background-image: url("../imgs/fundo.png");
  /*filter: brightness(1.5);*/
}

.separador-pequeno-left {
  margin-left: 2px;
  width: 200px;
  height: 6px;
  border-radius: 10px;
  background-image: url("../imgs/fundo.png");
  /*filter: brightness(1.5);*/
}

.separador-pp {
  width: 120px;
  margin: 10px auto;
  height: 4px;
  background-image: url("../imgs/fundo.png");
}
